
// import { ImageServer } from '../../../img/imageServer';


// const Profile01 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/group594.png`;
// const Profile02 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/group595.png`;
// const Profile03 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/group596.png`;
// const Profile04 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/group597.png`;

// const profile1 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse605.png`;
// const profile2 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse606.png`;
// const profile3 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse607.png`;
// const profile4 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse608.png`;
// const profile5 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse616.png`;
// const profile6 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse617.png`;
// const profile7 = `${ImageServer}img/Pitch-Catalyst/home-page-imgs/ellipse618.png`;



const profile01 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group596.png'
const profile02 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group680.png'
const profile03 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group677.png'
const profile04 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group675.png'
const profile05 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group672.png'
const profile06 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group671.png'
const profile07 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group670.png'
const profile08 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group669.png'
const profile09 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group678.png'
const profile10 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group676.png'
const profileImg01 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group673.png'
const profileImg02 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/our-clients-img/group679.png'



export const twelvethData = [
    {
        imgURL: profile01,
        profileIMG: profileImg01
    },
    {
        imgURL: profile02,
        profileIMG: profileImg01
    },
    {
        imgURL: profile03,
        profileIMG: profileImg01
    },
    {
        imgURL: profile10,
        profileIMG: profileImg02
    },

    {
        imgURL: profile04,
        profileIMG: profileImg01
    },
    {
        imgURL: profile05,
        profileIMG: profileImg01
    },
    {
        imgURL: profile06,
        profileIMG: profileImg01
    },
    {
        imgURL: profile09,
        profileIMG: profileImg02
    },
    {
        imgURL: profile07,
        profileIMG: profileImg01
    },
    {
        imgURL: profile08,
        profileIMG: profileImg01
    },



]