import React from 'react';
import './logo-vector-comp.scss';

const LogoPart01 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part01.png';
const LogoPart02 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part02.png';
const LogoPart03 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part03.png';
const LogoPart04 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part04.png';
const LogoPart05 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part05.png';
const LogoBottomPart = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-bottom-part.png';

export default function LogoVectorComp(props) {
    return (
        <div className="logo-vector-comp-main-container"
            style={{
                transform: props.CompOpacity < 0.4 ? 'translate(-150%, -37.5%) rotate(-90deg)' :
                    props.CompOpacity >= 0.4 && props.CompOpacity < 0.6 ? "translate(-95%, 13.4vh) rotate(0deg)" :
                        props.CompOpacity >= 0.6 && props.CompOpacity < 0.8 ? "translate(-40%, 10%)" :
                            props.CompOpacity >= 0.8 && "translate(0%, 10%)",
                width: props.CompOpacity >= 1 && '70vw',


            }}
        >
            <img src={LogoPart01} alt="" className="part-one"
                style={{
                    margin: props.CompOpacity >= 1 && '0% 5%',
                    transition: props.CompOpacity >= 1 && 'ease-in-out 1.5s'
                }}
            />
            <img src={LogoPart02} alt="" className="part-two"
                style={{
                    margin: props.CompOpacity >= 1 && '0% 5%',
                    transition: props.CompOpacity >= 1 && 'ease-in-out 1.5s',
                    opacity: props.CompOpacity >= 1 && '0.1',
                }}
            />
            <img src={LogoPart03} alt="" className="part-three"
                style={{
                    margin: props.CompOpacity >= 1 && '0% 5%',
                    transition: props.CompOpacity >= 1 && 'ease-in-out 1.5s',
                    opacity: props.CompOpacity >= 1 && '0.1',
                }}
            />
            <img src={LogoPart04} alt="" className="part-four"
                style={{
                    margin: props.CompOpacity >= 1 && '0% 5%',
                    transition: props.CompOpacity >= 1 && 'ease-in-out 1.5s',
                    opacity: props.CompOpacity >= 1 && '0.1',
                }}
            />
            <img src={LogoPart05} alt="" className="part-four"
                style={{
                    margin: props.CompOpacity >= 1 && '0% 5%',
                    transition: props.CompOpacity >= 1 && 'ease-in-out 1.5s'
                }}
            />

            <div className="bottom-part"
                style={{
                    top: props.CompOpacity < 0.4 ? '0' : props.CompOpacity >= 0.4 && '40%',
                    opacity: props.CompOpacity >= 0.8 && '0',
                    transition: props.CompOpacity >= 0.8 && props.CompOpacity < 1 && ' ease 0.8s'
                }}
            >

                <img src={LogoBottomPart} alt="" className="bottom-part-img"
                    style={{
                        top: props.CompOpacity < 0.4 ? '0' : props.CompOpacity >= 0.4 && '-40.6%',
                    }}
                />
            </div>
        </div>
    )
}
