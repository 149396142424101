import React from 'react';
import './bottom-gradient.scss';

export default function BottomGradient() {
  return (
    <div className="bottom-gradient-main-container">
         <div className="main-div">
                <div className="strip-div">
                    <div className="strip-one-div">
                    </div>
                    <div className="strip-two-div"></div>
                </div>
            </div>

    </div>
  )
}
