import React from 'react';
import './op-logo-vector-comp.scss';

const LogoPart01 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part01.png';
const LogoPart02 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part02.png';
const LogoPart03 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part03.png';
const LogoPart04 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part04.png';
const LogoPart05 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-part05.png';
// const LogoBottomPart = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/differentiator/logo-bottom-part.png';

export default function OPLogoVectorComp(props) {
    return (
        <div className="logo-vector-comp-main-container"
            style={{
                transform:
                    !props.Query && props.CompOpacity >= 1.2 ? 'translate(0%, 0vh)' :
                        props.Query && props.CompOpacity >= 1.2 && 'translate(0%, 0vh) rotate(90deg)',
                width: !props.Query && props.CompOpacity >= 1 ? '80vw' :
                    props.Query && props.CompOpacity >= 1 && '125vw',


            }}
        >
            <img src={LogoPart01} alt="" className="part-one"
                style={{
                    margin: props.CompOpacity >= 1.3 && '0% 1%',
                    transition: props.CompOpacity >= 1.3 && 'ease-in-out 1.5s'
                }}
            />
            <img src={LogoPart02} alt="" className="part-two"
                style={{
                    margin: props.CompOpacity >= 1.3 && '0% 2%',
                    transition: props.CompOpacity >= 1.3 && 'ease-in 1.5s',
                    // opacity: props.CompOpacity >= 1.3 && '0.1',
                }}
            />
            <img src={LogoPart03} alt="" className="part-three"
                style={{
                    margin: !props.Query && props.CompOpacity >= 1.3 ? '0% 20%' : props.Query && props.CompOpacity >= 1.3 && '0% 14%',
                    transition: props.CompOpacity >= 1.3 && 'ease-in-out 1s',
                    opacity: props.CompOpacity >= 1.3 && '0',
                }}
            />
            <img src={LogoPart04} alt="" className="part-four"
                style={{
                    margin: props.CompOpacity >= 1.3 && '0% 1%',
                    transition: props.CompOpacity >= 1.3 && 'ease-in-out 1.5s',
                    // opacity: props.CompOpacity >= 1.3 && '0.1',
                }}
            />
            <img src={LogoPart05} alt="" className="part-four"
                style={{
                    margin: props.CompOpacity >= 1.3 && '.30% 2%',
                    transition: props.CompOpacity >= 1.3 && 'ease-in-out 1.5s'
                }}
            />

        </div>
    )
}
