export const GeneralWritingCardData = [

    [
        {
            name1: 'Chat',
            name2: 'Sonic',
            paragraph: `A chatbot like ChatGPT but with real-time data, images & voice search.`
        },
        {
            name1: 'Personal',
            name2: 'Bios',
            paragraph: `Perfect bio copy that shows your expertise and drives more clients to you.`
        },
        {
            name1: 'Text',
            name2: 'Extender',
            paragraph: `Extend short sentences into more descriptive and interesting ones.`
        },
        {
            name1: 'Company',
            name2: 'Bio',
            paragraph: `Short and sweet company bio that will help you connect with your target audience.`
        },
        {
            name1: 'Content',
            name2: 'rephrase',
            paragraph: `Rephrase your content in a different voice and style to appeal to different readers.`
        },
        {
            name1: 'Content',
            name2: 'Shorten',
            paragraph: `Short your content in a different voice and style to appeal to different readers.`
        }
    ],













    [
        
        {
            name1: 'Stories',
            name2: '',
            paragraph: `Engaging and persuasive stories that will capture your reader's attention and interest.`
        },
        {
            name1: 'Bullet Point',
            name2: 'answers',
            paragraph: `Precise and informative bullet points that provide quick and valuable answers to your customers' questions.`
        },
        {
            name1: 'Passive to',
            name2: 'active voice',
            paragraph: `Easy and quick solution to converting your passive voice sentences into active voice sentences.`
        },
        {
            name1: 'Answers',
            name2: '',
            paragraph: `Instant, quality answers to any questions or concerns that your audience might have.`
        },
        {
            name1: 'Pros and cons',
            name2: '',
            paragraph: `List of the main benefits versus the most common problems and concerns.`
        }
    ]


]
