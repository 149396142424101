const Frame1 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20244.jpg';
const Frame2 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20245.png';
const Frame3 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20246.jpg';
const Frame4 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20248.jpg';
const Frame5 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20249.jpg';
const Frame6 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20250.jpg';
const Frame7 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20251.jpg';
const Frame8 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20263.jpg';
const Frame9 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20264.jpg';
const Frame10 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20265.jpg';
const Frame11 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20266.jpg';
const Frame12 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20268.jpg';
// import Frame13 from 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/Frame%20258.jpg';
const Frame14 = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/home-page-imgs/MacBook%20Pro%2014_%20-%20167.jpg';


export const PDFDate = [
    {
        imgURL: Frame1
    },
    {
        imgURL: Frame2
    },
    {
        imgURL: Frame3
    },
    {
        imgURL: Frame4
    },
    {
        imgURL: Frame5
    },
    {
        imgURL: Frame6
    },
    {
        imgURL: Frame7
    },
    {
        imgURL: Frame8
    },
    {
        imgURL: Frame9
    },
    {
        imgURL: Frame10
    },
    {
        imgURL: Frame11
    },
    {
        imgURL: Frame12
    },
    {
        // imgURL: Frame13
    },
    {
        imgURL: Frame14
    },

]




