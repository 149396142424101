export const listData=[
    {
        listPara: "APP FLOW STEP 01."
    },
    {
        listPara: "APP FLOW STEP 02."
    },
    {
        listPara: "APP FLOW STEP 03."
    },
    {
        listPara: "APP FLOW STEP 04."
    },
    {
        listPara: "APP FLOW STEP 05."
    },
    {
        listPara: "APP FLOW STEP 06."
    },
    {
        listPara: "APP FLOW STEP 07."
    },
    {
        listPara: "APP FLOW STEP 08."
    },
    {
        listPara: "APP FLOW STEP 09."
    }
]