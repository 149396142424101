export const ArticlesAndBlogsData = [
    {
        name1: 'AI Article',
        name2: 'writer 5.0',
        paragraph: `Factual, up-to-date instant articles based on your competitor's articles or your existing content.`
    },
    {
        name1: 'Paragraph',
        name2: 'Writer',
        paragraph: `Write short, punchy paragraphs that turn your readers into customers.`
    },
    {
        name1: 'AI Article',
        name2: 'Ideas',
        paragraph: `Article/blog ideas that you can use to generate more traffic, leads, and sales for your business.`
    },
    {
        name1: 'AI Article',
        name2: 'Outlines',
        paragraph: `Detailed article outlines that help you write better content on a consistent basis.`
    },
    {
        name1: 'Instant Article',
        name2: 'Writer',
        paragraph: `Instantly generate 1500-word articles with one click. This is an automated process, so your title must be very precise.`
    },
    {
        name1: 'Article',
        name2: 'Rewriter',
        paragraph: `Copy an article, paste it in to the program, and with just one click you'll have an entirely different article to read.`
    },
    {
        name1: 'Conclusion',
        name2: 'writer',
        paragraph: `Powerful conclusion copy that will make a reader take action.`
    }
]
