const solutionCompData = [
    {
        heading: "FEATURE-01",
        para: " How does your first solution address the identified problem?"
    },
    {
        heading: "FEATURE-02",
        para: "How does your second solution tackle the identified problem?"

    }, {
        heading: "FEATURE-03",
        // para:"  Your renting reward zone, assisting you with negotiating more."
        para: "How does the third solution contribute to solving the problem?"

    }, {
        heading: "FEATURE-04",
        para: "How is the fourth solution designed to overcome the problem?"
    },
];
export default solutionCompData;