const ProblemCompData = [

    {
        property: [

            //------------------------------Landlord-----------------------------------
            {
                heading: "SUB-HEADING",
                firstPoint: "What is the key problem your business?",
                secondPoint: "How widespread or prevalent is this problem?",
                thirdPoint: "Who exactly is affected by this problem?"
            },
            {
                heading: "SUB-HEADING",

                firstPoint: "Can you quantify the impact of this problem?",
                secondPoint: "How does the problem affect different stakeholders?",
                thirdPoint: ""
            },
            {
                heading: "SUB-HEADING",

                firstPoint: "How does this problem affect business outcomes or key metrics?",
                secondPoint: "What are the current solutions?",
                thirdPoint: "What obstacles exist in solving this problem today?"
            }
        ]
    },
    {
        property: [

            //---------------------Tenant---------------------------------------------
            {
                heading: "SUB-HEADING",
                firstPoint: "What is the key problem your business?",
                secondPoint: "How widespread or prevalent is this problem?",
                thirdPoint: "Who exactly is affected by this problem?"
            },
            {
                heading: "SUB-HEADING",

                firstPoint: "Can you quantify the impact of this problem?",
                secondPoint: "How does the problem affect different stakeholders?",
                thirdPoint: ""
            },
            {
                heading: "SUB-HEADING",

                firstPoint: "How does this problem affect business outcomes or key metrics?",
                secondPoint: "What are the current solutions?",
                thirdPoint: "What obstacles exist in solving this problem today?"
            }
        ]
    },
    {
        property: [
            //---------------------------Broker-----------------------------------------------------
            {
                heading: "SUB-HEADING",
                firstPoint: "What is the key problem your business?",
                secondPoint: "How widespread or prevalent is this problem?",
                thirdPoint: "Who exactly is affected by this problem?"
            },
            {
                heading: "SUB-HEADING",

                firstPoint: "Can you quantify the impact of this problem?",
                secondPoint: "How does the problem affect different stakeholders?",
                thirdPoint: ""
            },
            {
                heading: "SUB-HEADING",

                firstPoint: "How does this problem affect business outcomes or key metrics?",
                secondPoint: "What are the current solutions?",
                thirdPoint: "What obstacles exist in solving this problem today?"
            }
        ]
    }

];
export default ProblemCompData;