export const MoreCategoryCardData = [

    [
        {
            name1: 'Tone',
            name2: ' Changer',
            paragraph: `Change the tone of your writing to match your audience and copy.`
        },
        {
            name1: 'Song',
            name2: 'Lyrics',
            paragraph: `Unique song lyrics that will be perfect for your next hit song.`
        },
        {
            name1: 'Pain-Agitate',
            name2: 'Solutions',
            paragraph: `The main formula for writing high-converting sales copy.`
        },
        {
            name1: 'Review',
            name2: 'Responder ',
            paragraph: `Automatically generate personalized replies for both positive and negative reviews.`
        },
       
    ]
    ,


    [
        {
            name1: 'AIDA ',
            name2: 'Framework',
            paragraph: `Tried and tested formula of Attention, Interest, Desire, Action that is proven to convert.`
        },
        {
            name1: 'Analogy',
            name2: 'Maker',
            paragraph: `Unique analogies that make your sales pitch more memorable and engaging.`
        },
        {
            name1: 'Translate',
            name2: '',
            paragraph: `Powerful conclusion copy that will make a reader take action.`
        }
    ]
]
