export const OurPricingData = [
    {
        name: 'static deck',
        price: '35 K',
        price_usd: '700',
        heading_1: 'Essential Impact',
        heading_2: 'static deck',
        detail_lists: [
            { list: `Professional design aligned with your brand guidelines.` },
            { list: `Customized layout amplifying your startup's story.` },
            { list: `Engaging graphics to visualize your data.` },
            { list: `High-resolution PDF format delivery.` },
            { list: `Up to 3 revisions included.` },
            { list: `Estimated completion time: 2-3 weeks.` },
            { list: `A perfect fit for startups on a budget aiming to make a substantial impact.` },
            // { list: `Suited for startups on a tight budget seeking to make an impact.` },
            // { list: `Excellent for startups on a tight budget looking to make an impact.` },

        ]


    },




    {
        name: 'Dynamic Deck',
        price: '1.2 lac',
        price_usd: '2500',
        heading_1: 'Dynamic momentum',
        heading_2: 'Dynamic Template deck',
        detail_lists: [
            { list: `Interactive web-based pitch deck using pre-existing templates.` },
            { list: `Layout and color customization aligning with brand guidelines.` },
            { list: `Interactive elements such as clickable links and embedded videos.` },
            { list: `Content editing, AI generator, and analytics in accessible dashboard` },
            { list: `Analytics and insights for targeted user experience.` },
            { list: `API live data implementations for real-time updates.` },
            { list: `Subdomains for targeted user experience.` },
            { list: `Estimated completion time: 3-4 weeks.` },
            { list: `Ideal for startups balancing dynamic interactivity and budget.` },


        ]


    },
    {
        name: 'Dynamic Unique',
        price: '2 Lac',
        price_usd: '4000',
        heading_1: 'Ultimate Engage',
        heading_2: 'Dynamic Unique deck',
        detail_lists: [
            { list: `Fully interactive, custom-designed web-based pitch deck.` },
            { list: `Unique design aligned with your startup's brand and story.` },
            { list: `Advanced interactive elements: scroll-triggered animations, hover effects.` },
            { list: `Responsive design for all devices.` },
            { list: `Analytics integration for tracking engagement.` },
            { list: `API live data implementations for real-time updates.` },
            { list: `Dashboard access: edit content, use AI generator, insights, subdomains.` },
            { list: `Estimated completion time: 6-8 weeks.` },
            { list: `Ideal for startups looking for a blend of dynamic interactivity and budget friendliness.` },

        ]


    },
]