export const vector = [
    {
        x: 75,
        y: -26,
        rotation: 25
    },
    {
        x: 45,
        y: -4,
        rotation: 25
    },
    {
        x: -8,
        y: -16,
        rotation: 20
    },
    {
        x: 50,
        y: 2,
        rotation: -85
    },
    {
        x: 35,
        y: 2,
        rotation: 15
    },
    {
        x: 70,
        y: 2,
        rotation: 25
    },
    {
        x: -8,
        y: -6,
        rotation: 16
    },
    {
        x: 58,
        y: -4,
        rotation: -25
    },
    {
        x: 80,
        y: 0,
        rotation: -25
    },

    {
        x: 1,
        y: 2,
        rotation: -85
    },
    {
        x: 70,
        y: -15,
        rotation: 55
    },
    {
        x: 15,
        y: -4,
        rotation: 25
    },
    {
        x: 75,
        y: -6,
        rotation: -75
    },
    {
        x: 25,
        y: 2,
        rotation: 85
    },
    {
        x: 22,
        y: -8,
        rotation: -60
    },
    {
        x: 65,
        y: -12,
        rotation: 5
    },
    {
        x: 80,
        y: -14,
        rotation: -25
    },
    {
        x: 2,
        y: -6,
        rotation: 25
    },
    {
        x: 65,
        y: -2,
        rotation: 15
    },
    {
        x: 34,
        y: -6,
        rotation: 5
    },

]
export const vector2 = [
    {
        x: 75,
        y: -106,
        rotation: 25
    },
    {
        x: 45,
        y: -82,
        rotation: 25
    },
    {
        x: -8,
        y: -25,
        rotation: 20
    },
    {
        x: 35,
        y: -22,
        rotation: -85
    },
    {
        x: 50,
        y: -10,
        rotation: 15
    },
    {
        x: 70,
        y: 2,
        rotation: -25
    },
    {
        x: -8,
        y: -2,
        rotation: 16
    },
    {
        x: 50,
        y: 5,
        rotation: -25
    },
    {
        x: 80,
        y: -65,
        rotation: -25
    },

    {
        x: -4,
        y: 12,
        rotation: -85
    },
    {
        x: 70,
        y: -85,
        rotation: 55
    },
    {
        x: 15,
        y: -24,
        rotation: 25
    },
    {
        x: 75,
        y: -52,
        rotation: -75
    },
    {
        x: 20,
        y: 4,
        rotation: 15
    },
    {
        x: 22,
        y: -12,
        rotation: -60
    },
    {
        x: 75,
        y: -12,
        rotation: 5
    },
    {
        x: 80,
        y: -34,
        rotation: -25
    },
    {
        x: 2,
        y: -12,
        rotation: -25
    },
    {
        x: 65,
        y: -22,
        rotation: 15
    },
    {
        x: 34,
        y: 6,
        rotation: 55
    },

]